import { createRouter, createWebHashHistory } from 'vue-router'
import globalRoutes from './globalRoutes'
import { ref } from 'vue'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.3
})

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  isAddDynamicMenuRoutes: false,
  routes: globalRoutes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('csrf_access_token')
  const path_public_list = [
    '/activate',
    '/activate_transit',
    '/pswd_transit',
  ]
  NProgress.start()
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  const toPath = to.path;
  if (path_public_list.indexOf(toPath) == -1) {

    if (to.matched.length === 0) {
      next({
        name: 'home',
      })
    }else{
      next();
    }

  }
  // 将要进入的路径'/path_public_list'
  else {
    next();
  }
})

router.afterEach((to, from, next) => {
    NProgress.done()
})

export default router
